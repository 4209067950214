import { useReactiveVar } from '@apollo/client';
import { TeliaButton, TeliaIcon } from '@teliads/components/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TeliaSearchInput } from '../Navigation';
import WebsitesNavLink from '../Navigation/components/Websites/WebsitesNavLink';

import { urlLanguage } from 'apollo';
import BaseModal from 'components/custom/base-modal/BaseModal';
import Logo from 'components/custom/logo/Logo';
import MobileHeaderBackButton from 'components/custom/mobile-header/mobile-header-back-button/MobileHeaderBackButton';
import MobileHeaderNavigation from 'components/custom/mobile-header/mobile-header-navigation/MobileHeaderNavigation';
import { NavigationLink } from 'components/voca/navigation/navigation-stub/models';
import NavigationStub from 'components/voca/navigation/navigation-stub/NavigationStub';
import useNavigationSearch from 'utils/hooks/useNavigationSearch';

import 'components/custom/mobile-header/MobileHeader.scss';

export default function MobileHeader() {
  const { t, i18n } = useTranslation();

  const currentUrlLanguage = useReactiveVar(urlLanguage);
  const homeLink: NavigationLink = NavigationStub.get(currentUrlLanguage!)!.findLinkBy('slug', '')!;

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedNavLink, setSelectedNavLink] = useState<NavigationLink | null>(null);
  const [isBackToHome, setIsBackToHome] = useState<boolean>(false);
  const { onSearch, searchQuery, setSearchQuery, suggestions } = useNavigationSearch();

  const onSelectedNavLink = (navigationLink: NavigationLink) => {
    setSelectedNavLink(navigationLink);
  };

  const navigateBack = () => {
    setSelectedNavLink(null);
    setIsBackToHome(true);
  };

  const onClose = () => {
    setIsMenuOpen(false);
    setIsSearchOpen(false);
    setSelectedNavLink(null);
    setIsBackToHome(true);
  };

  const handleSearch = () => {
    setIsSearchOpen(false);
    onSearch();
  };

  useEffect(() => {
    isBackToHome && setIsBackToHome(false);
  }, [isBackToHome]);

  return (
    <header className="mobile-header" data-testid="mobile-header">
      <div slot="logo">
        <Logo />
      </div>

      <nav className="mobile-header__icons">
        <WebsitesNavLink isMobile />
        <TeliaButton
          ally-label="open navigation menu"
          variant="tertiary-purple"
          size="sm"
          className="mobile-header__menu-button"
          data-testid="mobile-header-menu-search-button"
          onClick={() => setIsSearchOpen(true)}
        >
          <TeliaIcon
            size="md"
            name={'search'}
            ally-title={t('telia-navigation:icon.magnifying_glass')}
          />
        </TeliaButton>

        <TeliaButton
          ally-label="open navigation menu"
          variant="tertiary-purple"
          size="sm"
          className="mobile-header__menu-button"
          data-testid="mobile-header-menu-button"
          onClick={() => setIsMenuOpen(true)}
        >
          <TeliaIcon name="menu" size="md" />
        </TeliaButton>
      </nav>

      <BaseModal
        variant="side"
        isFullWidth
        header={
          <MobileHeaderBackButton navigateBack={navigateBack} selectedNavLink={selectedNavLink} />
        }
        isOpen={isMenuOpen || isSearchOpen}
        onClose={onClose}
        focusFirstInteractive={!isSearchOpen}
      >
        {isSearchOpen ? (
          <div className="mobile-header__search">
            <TeliaSearchInput
              i18n={i18n}
              value={searchQuery}
              updateValue={setSearchQuery}
              onSearch={handleSearch}
              language={i18n.language}
              searchSuggestions={suggestions}
              isAllResultsLinkHidden
              placeholder={`${t('actions.search')}...`}
            />
          </div>
        ) : (
          <MobileHeaderNavigation
            rootLevel={homeLink}
            selectedNavLink={selectedNavLink}
            onNavigationChange={onClose}
            onSelectedNavLink={onSelectedNavLink}
            isBackToHome={isBackToHome}
          />
        )}
      </BaseModal>
    </header>
  );
}
