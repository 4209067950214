import { useReactiveVar } from '@apollo/client';
import { TeliaHeading, TeliaSkeleton } from '@teliads/components/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { urlLanguage } from 'apollo';
import Paragraph from 'components/voca/paragraph/Paragraph';
import { ArticleBodyBlockPersonFragment } from 'graphql/__generated/sdk';
import { generateEntrySlug } from 'utils/mappers';
import { Dimensions, createMultipleSizeImages } from 'utils/utils';

import './BoardMemberCard.scss';

interface Props {
  data: ArticleBodyBlockPersonFragment;
}

export default function BoardMemberCard({ data }: Props) {
  const currentUrlLanguage = useReactiveVar(urlLanguage);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { fullName, position } = data;

  const linkTo = generateEntrySlug(data, currentUrlLanguage);

  const BoardDimensions: Dimensions = {
    small: ['2:3', 650],
    medium: ['2:3', 850],
    large: ['2:3', 1125],
  };

  const [image] = data.images.items;
  const imageSizes = createMultipleSizeImages(
    {
      url: image.image.url,
      altText: image.altText,
      focusArea: image.focusArea,
    },
    BoardDimensions
  );

  return (
    <Link to={linkTo} key={fullName} className="board-member-card" data-testid="board-member-card">
      <div className="board-member-card__image-container">
        <picture>
          <source media="(max-width: 450px)" srcSet={imageSizes.small} />
          <source media="(max-width: 650px)" srcSet={imageSizes.medium} />

          {!isImageLoaded && (
            <TeliaSkeleton class="board-member-card__image" dataTestid="board-member-skeleton" />
          )}

          <img
            src={imageSizes.large}
            alt={imageSizes.altText}
            onLoad={() => setIsImageLoaded(true)}
            className={`board-member-card__image${!isImageLoaded ? '--hidden' : ''}`}
            data-testid="board-member-image"
          />
        </picture>
      </div>

      <div className="board-member-card__content">
        <TeliaHeading variant="title-300" tag="h3">
          {fullName}
        </TeliaHeading>
        <Paragraph className="collection-list__item-text" variant="paragraph-100">
          {position}
        </Paragraph>
      </div>
    </Link>
  );
}
