import { TeliaLogo } from '@teliads/components/react';
import i18next from 'i18next';
import { ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import { useHeader } from '../../contexts/Header.context';
import en from '../../locales/en.json';
import sv from '../../locales/sv.json';
import HeaderSearchInput from '../HeaderSearchInput/HeaderSearchInput';
import LanguageSwitcher from '../LanguageSwitcher';
import MegaMenu from '../MegaMenu';
import { Section as SectionType } from '../MegaMenu/types';
import NavItemButton from '../NavItemButton';
import NavItemLink from '../NavItemLink';
import WebsitesNavLink from '../Websites/WebsitesNavLink';

import styles from './Header.module.scss';

import { mappedTeaserData } from 'components/voca/the-header/transformers';

type LinkType = {
  children?: SectionType[];
  url: string;
  name: string;
  icon?: string;
};

type Props = {
  isSticky?: boolean;
  selectedLanguage?: string;
  availableLanguages?: string[];
  links: LinkType[];
  navTeasers?: mappedTeaserData;
  onSearch?: () => void;
  onLanguageChange?: (lang: string) => void;
  i18n: typeof i18next;
  logo?: ReactNode;
  searchSuggestions?: string[];
  searchPlaceholder?: string;
  handleSearchInput?: (value: SetStateAction<string>) => void;
  isAllResultsLinkHidden?: boolean;
};

export default function Header({
  isSticky,
  selectedLanguage,
  availableLanguages,
  links,
  navTeasers,
  onSearch,
  onLanguageChange,
  i18n,
  logo = <TeliaLogo />,
  searchSuggestions,
  searchPlaceholder,
  handleSearchInput,
  isAllResultsLinkHidden = false,
}: Props) {
  i18n.addResourceBundle('en', 'telia-navigation', en);
  i18n.addResourceBundle('sv', 'telia-navigation', sv);

  const [openMegaMenuName, setOpenMegaMenuName] = useState('');
  const linksWithMegaMenu = links.filter((link) => link.children?.length);

  const toggleMegaMenu = (megaMenuName: string) => {
    setOpenMegaMenuName((prevState) => {
      return prevState === megaMenuName ? '' : megaMenuName;
    });
  };

  const headerRef = useRef<HTMLElement>(null);
  const navItemsRef = useRef<HTMLUListElement>(null);
  const megaMenuRef = useRef<HTMLDivElement>(null);
  const { setHeaderHeight } = useHeader();

  useEffect(() => {
    if (headerRef?.current) {
      const resizeObserver = new ResizeObserver(() => {
        headerRef.current && setHeaderHeight(headerRef.current.offsetHeight);
      });

      resizeObserver.observe(headerRef.current);
      return () => resizeObserver.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef]);

  useEffect(() => {
    const hideMegaMenu = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        navItemsRef.current &&
        megaMenuRef.current &&
        !navItemsRef.current.contains(target) &&
        !megaMenuRef.current.contains(target)
      ) {
        toggleMegaMenu('');
      }
    };

    document.addEventListener('click', hideMegaMenu);
    return () => document.removeEventListener('click', hideMegaMenu);
  }, []);

  return (
    <header
      ref={headerRef}
      className={`${styles.header} ${isSticky ? styles['header--sticky'] : ''}`}
      data-testid="telia-header"
    >
      {selectedLanguage && availableLanguages && onLanguageChange && (
        <LanguageSwitcher
          selectedLanguage={selectedLanguage}
          availableLanguages={availableLanguages}
          onLanguageChange={onLanguageChange}
        />
      )}

      <nav className={styles.navigation} data-testid="nav">
        {logo}
        <ul className={styles['navigation__list']} ref={navItemsRef}>
          {links.map((link) => (
            <li
              key={link.name}
              className={styles['navigation__item']}
              data-testid="navigation-item"
            >
              {link.children && link.children.length > 0 ? (
                <NavItemButton
                  onClick={() => toggleMegaMenu(link.name)}
                  selected={link.name === openMegaMenuName}
                >
                  <span>{link.name}</span>
                </NavItemButton>
              ) : (
                <NavItemLink
                  url={link.url}
                  title={link.name}
                  icon={link?.icon}
                  onClick={() => toggleMegaMenu(link.name)}
                />
              )}
            </li>
          ))}
        </ul>

        <WebsitesNavLink />
        {onSearch && selectedLanguage && handleSearchInput && (
          <HeaderSearchInput
            selectedLanguage={selectedLanguage}
            handleSearchInput={handleSearchInput}
            onSearch={onSearch}
            searchSuggestions={searchSuggestions || []}
            isAllResultsLinkHidden={!!isAllResultsLinkHidden}
            placeholder={searchPlaceholder}
          />
        )}
      </nav>

      <div ref={megaMenuRef}>
        {linksWithMegaMenu.map((link) => (
          <MegaMenu
            key={link.name}
            handleNavigation={() => toggleMegaMenu('')}
            open={link.name === openMegaMenuName}
            rootLink={{ url: link.url, title: link.name }}
            sections={link.children || []}
            navTeasers={navTeasers || []}
          />
        ))}
      </div>
    </header>
  );
}
