import { TeliaIcon } from '@teliads/components/react';
import { arrowRight } from '@teliads/icons';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useLinkClickHandler } from 'react-router-dom';

import { NavLink as NavLinkProps, Variant } from '../../types/common';

import styles from './NavLink.module.scss';

const NavLink = forwardRef(function NavLink(
  {
    url,
    title,
    target = '_self',
    variant = Variant.NAVIGATION,
    language = 'en',
    onClick,
    isExternal = false,
  }: NavLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const classNames = useMemo(
    () =>
      [
        styles['nav-link'],
        ...(variant === Variant.LINK ? [styles['nav-link--link']] : []),
        ...(variant === Variant.LEVEL_1 ? [styles['nav-link--level-1']] : []),
      ].join(' '),
    [variant]
  );
  const { t } = useTranslation();

  const href = useHref(url);
  const handleClick = useLinkClickHandler(url, {
    target: target || '_self',
  });

  return (
    <a
      ref={ref}
      href={isExternal ? url : href}
      target={target || ''}
      data-testid="nav-link"
      className={classNames}
      onClick={(event) => {
        if (!isExternal) {
          event.preventDefault();
          onClick?.();
          handleClick(event);
        }
      }}
    >
      <span className={styles['nav-link__text']}>{title}</span>
      {variant === Variant.LEVEL_1 && (
        <TeliaIcon
          data-testid="nav-link-icon"
          className={styles['nav-link__icon']}
          ally-title={t('telia-navigation:icon.arrow_right', { lng: language })}
          size="sm"
          svg={arrowRight.svg}
        />
      )}
    </a>
  );
});

export default NavLink;
