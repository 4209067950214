import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Inline, Block } from '@contentful/rich-text-types';
import { Button, Heading, Paragraph } from '@purpurds/purpur';
import { TeliaDivider } from '@teliads/components/react';
import './Calendar.scss';
import { format } from 'date-fns';
import { ReactNode, useState } from 'react';

import AddToCalendar from './calendarButton';

import PurpurIcon from 'components/purpur/Icon/Icon';

type CalendarProps = {
  title: string;
  body: string;
  date: string;
  drawerBody: ReactNode;
  calendarDescription: Inline | Block;
  url?: string;
};

export default function Calendar({
  title,
  body,
  date,
  drawerBody,
  calendarDescription,
  url,
}: CalendarProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const formattedDate = format(new Date(date), 'dd MMM yyyy').toUpperCase();
  const [day, month, year] = formattedDate.split(' ');

  const plainDescription = documentToPlainTextString(calendarDescription);

  return (
    <>
      <div className="calendar">
        <div className="calendar__date">
          <Heading tag="h3" variant="display-50">
            {day}
          </Heading>
          <span>
            {month} {year}
          </span>
        </div>
        <div className="calendar__description">
          <Heading tag="h4" variant="title-300">
            {title}
          </Heading>
          <Paragraph className="description__paragraph">{body}</Paragraph>
        </div>
        <div className="calendar__buttons">
          <AddToCalendar
            eventTitle={title}
            startDateTime={date}
            description={plainDescription}
            calendarUrl={url}
          />
          <Button
            className={`calendar__drawer-button${!drawerBody ? '--hidden' : ''}`}
            variant="secondary"
            iconOnly
            aria-label="expand calendar"
            fullWidth={false}
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
          >
            <PurpurIcon iconName={!isDrawerOpen ? 'plus' : 'minus'} size={'md'} />
          </Button>
        </div>
        <section className="drawer-container">
          <div className={`calendar__drawer${isDrawerOpen ? '--active' : ''}`}>
            <Paragraph className="drawer__paragraph">{body}</Paragraph>
            <Paragraph>{drawerBody}</Paragraph>
          </div>
        </section>
      </div>

      <TeliaDivider />
    </>
  );
}
