import { Icon, iconList } from '@purpurds/purpur';

interface Props {
  iconName: keyof typeof iconList;
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

const PurpurIcon = ({ iconName, size = 'md', className }: Props) => {
  return <Icon svg={iconList[iconName]} size={size} className={className} />;
};

export default PurpurIcon;
