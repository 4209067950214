import { iconList } from '@purpurds/purpur';
import { NavLink } from 'react-router-dom';

import styles from './NavItemLink.module.scss';

import PurpurIcon from 'components/purpur/Icon/Icon';

type Props = {
  url: string;
  title: string;
  variant?: 'primary' | 'secondary';
  ariaLabel?: string;
  onClick?: () => void;
  icon?: string | undefined;
  iconSize?: 'sm' | 'md' | 'lg';
};

export default function NavItemLink({
  url,
  title,
  variant = 'primary',
  ariaLabel,
  onClick,
  icon,
  iconSize = 'sm',
}: Props) {
  const classNames = `${styles['nav-item-link']} ${styles[`nav-item-link--${variant}`]}`;

  return (
    <NavLink
      data-testid="nav-item-link"
      className={({ isActive }) =>
        isActive ? `${classNames} ${styles['nav-item-link--active']}` : classNames
      }
      to={url}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <div className={styles['nav-item-content']}>
        {icon && (
          <>
            <PurpurIcon iconName={icon as keyof typeof iconList} size={iconSize} />
            <span className={styles['nav-item-spacer']}></span>
          </>
        )}
        {title}
      </div>
    </NavLink>
  );
}
